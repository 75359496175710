import React from "react";
import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll";
const NavLinksInner = () => {
  const handleDropdownStatus = e => {
    let clickedItem = e.currentTarget.parentNode;
    clickedItem.querySelector(".dropdown-list").classList.toggle("show");
  };
  return (
    <ul className="main-nav__navigation-box one-page-scroll-menu">
      <li>
        <Link to="/">Galego</Link>
      </li>
      <li>
        <Link to="/#features">Castellano</Link>
      </li>
    </ul>
  );
};

export default NavLinksInner;
